import Sidebar from './components/Sidebar'

function App() {
  return (
    <div className="main">
      <Sidebar />
      <div className="container">
        <h1 className="title">Welcome</h1>
        <p className="info">This is Sai Vardhan Myneni, i am recent grad and at prest working as a intern at EVEIT. This is my blog </p>
        <button className="btn">Explore now</button>
        
      </div>
    </div>
  )
}

export default App